<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Edit warehouse">
                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="warehouse.name"/>
                </b-form-group>

                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Lat</label>
                            <b-form-input :value="warehouse.location[1]" v-on:input="updateMarkerLocationLat"/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <label>Lng</label>
                            <b-form-input :value="warehouse.location[0]" v-on:input="updateMarkerLocationLng"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <div id="map" style="width: 100%; height: 500px"></div>

                <hr/>
                <b-button variant="warning" @click="saveWarehouse">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow} from 'bootstrap-vue'
    import mapboxgl from 'mapbox-gl'
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        components: {
            BCard,
            BOverlay,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            BButton
        },
        data() {
            return {
                dataLoaded: false,

                map: null,
                marker: null,

                warehouse: {
                    name: '',
                    location: [
                        this.$store.state.app.default_map_view.center[0],
                        this.$store.state.app.default_map_view.center[1]
                    ]
                }
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/warehouse/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.warehouse = response.data
                    thisIns.marker.setLngLat(thisIns.warehouse.location)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveWarehouse() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/warehouse/${  this.$route.params.id}`, this.warehouse)
                savePromise.then(function() {
                    thisIns.$printSuccess('Warehouse saved')
                    thisIns.$router.go(-1)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            attachMap() {
                mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: this.$store.state.app.default_map_view.center,
                    zoom: this.$store.state.app.default_map_view.zoom
                })

                this.marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat(this.$store.state.app.default_map_view.center).addTo(this.map)

                this.marker.on('dragend', this.onMarkerDragEnd)
            },
            onMarkerDragEnd() {
                this.$set(this.warehouse.location, 0, this.marker.getLngLat().lng)
                this.$set(this.warehouse.location, 1, this.marker.getLngLat().lat)
            },
            updateMarkerLocationLat(val) {
                this.$set(this.warehouse.location, 1, val)
                this.marker.setLngLat(this.warehouse.location)
            },
            updateMarkerLocationLng(val) {
                this.$set(this.warehouse.location, 0, val)
                this.marker.setLngLat(this.warehouse.location)
            }
        },
        mounted() {
            this.attachMap()
            this.loadData()
        }
    }
</script>